import { useState } from 'react';
import type { Iframe as Item } from 'types';

interface Props {
  index?: number;
  item: Item
}

export default function Iframe({ index, item: { src, width, height } }: Props) {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <iframe
      onLoad={() => setIsLoaded(true)}
      title={`iFrame ${index ? index : 0}`}
      src={src}
      width={width}
      height={height}
      allowFullScreen
      loading="lazy"
      className={isLoaded ? 'opacity-100' : 'opacity-0'}
    />
  );
}