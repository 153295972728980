import type { Platform } from 'types';

// Icons
import { FaSpotify } from 'react-icons/fa';
import { RiSoundcloudFill } from 'react-icons/ri';

interface Props {
  platform: Platform;
}

// Capisco quale piattaforma
const logo: Record<Platform, JSX.Element> = {
  spotify: <FaSpotify className="spotify" />,
  soundcloud: <RiSoundcloudFill className="soundcloud" />
};

export default function PlatformLogo({ platform }: Props) {
  return logo[platform];
}