import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { Container, Row, Col, Button } from 'react-bootstrap';
import type { Section, ToastState } from 'types';
import { getSections } from '../utils/services';

// Components
import Loading from './Loading';
import PlatformLogo from './PlatformLogo';
import Iframe from './Iframe';
import Footer from './Footer';

// Icons
import { MdVerified } from 'react-icons/md';

// Dynamic components
const Toast = React.lazy(() => import('./Toast'));

export default function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState<Section[]>([]);

  // Toast
  const [toast, setToast] = useState<ToastState>({ show: false });

  const getItems = async () => {
    try {
      const { data } = await getSections();
      setItems(data);
    } catch (err) {
      console.error(err);

      // Apro il toast
      setToast({
        show: true,
        title: 'Errore',
        content: 'Si è verificato un errore durante il caricamento della pagina.'
      });
    }
  };

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
      getItems();
    }
  }, [isLoaded]);

  return (
    <>
      <div className="my-3 my-lg-5">
        <Container as="main">
          <div className="bg-white py-4 px-3 px-lg-5 rounded shadow-sm">
            <header className="d-flex justify-content-end mb-3">
              <h1 className="d-flex align-items-center mb-0 lh-1">
                <span className="me-2 text-uppercase">Matteo Manna</span>
                <MdVerified
                  title="Profilo verificato"
                  style={{
                    width: 30,
                    height: 30,
                    color: '#4cb3ff'
                  }}
                />
              </h1>
            </header>
            {items.length === 0 ? (
              <Loading />
            ) : items.map(({ title, platform, button, items }, key1) => (
              <section key={key1} className="mb-5">
                <div className="d-block d-lg-flex justify-content-between align-items-center mb-3">
                  <h2 className="d-flex align-items-center mb-3 mb-lg-0 lh-1">
                    <PlatformLogo platform={platform} />
                    <span className="ms-3 fw-lighter">{parse(title)}</span>
                  </h2>
                  {button &&
                    <Button
                      as="a"
                      href={button.href}
                      target={button.target}
                      rel="noreferrer noopener"
                      variant={button.variant}
                      className="text-uppercase px-3 rounded-pill text-nowrap"
                    >
                      {parse(button.label)}
                    </Button>
                  }
                </div>
                {items.length === 1 ? (
                  <Iframe item={items[0]} />
                ) : (
                  <Row>
                    {items.map((item, key2) => (
                      <Col key={key2} md={6} xl={items.length === 2 ? 6 : 4} className="mb-3">
                        <Iframe
                          index={key2}
                          item={item}
                        />
                      </Col>
                    ))}
                  </Row>
                )}     
              </section>
            ))}
          </div>
        </Container>
        <Footer />
      </div>
      {toast.show &&
        <Toast
          setToast={setToast}
          show={toast.show}
          title={toast.title}
          content={toast.content}
        />
      }
    </>
  );
}